<template>
  <!-- 设备维护 -->
  <div class="role_index" id="roleList">
    <div class="role_content">
      <div class="role_button">
        
        <el-input
          placeholder="设备名称"
          style="width: 250px"
          v-model="searchForm.deviceName"
          clearable
        ></el-input>

        <!-- <el-input
          placeholder="url"
          class="ml20"
          style="width: 250px"
          v-model="searchForm.deviceUrl"
          suffix-icon="el-icon-search"
        ></el-input> -->

        <el-button type="primary" class="ml30" @click="search" style="margin-top: 20px"
          >查询</el-button
        >

      </div>
      <div style="height: 20px; background: #f6f7f9"></div>
      <div style="padding: 0 20px">
        <el-button type="primary" @click="addRole" style="margin: 20px 0"
          >新增</el-button
        >
        <el-table border :data="tableData">
          <el-table-column prop="sjEnterpriseId" label="省级企业ID"></el-table-column>
          <el-table-column prop="ienterpriseName" label="所属企业"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称"></el-table-column>
          <el-table-column prop="deviceType" label="设备类型"></el-table-column>
          <el-table-column prop="deviceManufacturer" label="设备厂商"></el-table-column>
          <el-table-column prop="deviceAddr" label="设备地址"></el-table-column>
          <el-table-column prop="videoUrl" label="视频地址"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="editRole(scope.row)" size="small"
                >编辑</el-button
              >
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                @confirm="delRole(scope.row.id)"
                title="是否确认删除此设备?"
              >
                <el-button
                  slot="reference"
                  type="text"
                  style="margin-left: 12px"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right">
        <div style="margin-top: 12px; display: inline-block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
      <el-form
        :model="form"
        ref="cdForm"
        style="width: 80%; margin: 20px auto 0"
        :rules="rules"
        label-width="95px"
      >
        <el-form-item label="所属企业" prop="ienterpriseName">
            <el-select @change="selectChange" v-model="form.ienterpriseName" placeholder="请选择" style="margin-right: 14px">
                <el-option
                v-for="item in options1"
                :key="item.id"
                :label="item.ienterpriseName"
                :value="item.ienterpriseName">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="form.deviceName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-input v-model="form.deviceType" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备厂商">
          <el-select @change="selectChanges" v-model="form.deviceManufacturer" placeholder="请选择" style="margin-right: 14px">
              <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备地址">
           <el-input v-model="form.deviceAddr" autocomplete="off"></el-input> 
        </el-form-item>
        <el-form-item label="视频地址">
          <el-input v-model="form.videoUrl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
          deviceName: '',
      },
      tableData: [],
      dialogFormVisible: false,
      form: {},
      options: [{
        value: 'HK',
        label: '海康'
      },{
        value: 'DH',
        label: '大华'
      },{
        value: 'FM',
        label: '飞梦'
      },{
        value: 'JD',
        label: '竟道'
      }],
      options1: [],
      dialogTitle: "新增同步设备",
      roleDialog: false,
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules: {
        ienterpriseName: {
          required: true,
          message: "请输入所属企业",
          trigger: "blur",
        },
        deviceName: {
          required: true,
          message: "请输入设备名称",
          trigger: "blur",
        },
        
      },
      data: [],
    };
  },
  mounted() {
    this.enterprise();
    this.search();
    if (window.innerHeight) {
      var winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      var winHeight = document.body.clientHeight;
    }
    document.getElementById("roleList").style.minHeight = winHeight - 90 + "px";
  },
  methods: {
    enterprise () {
      this.qa.sjDeviceQueryForList({}).then((res) => {
        console.log(res)
        this.options1 = res.data;
      })
    },
    search() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        ...this.searchForm
      };
      this.qa.sjDeviceQueryForPageList(params).then((res) => {
        if (res.respCode == "0000") {
          this.tableData = res.data;
          this.pageInfo.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    selectChange (val) {
      console.log(val)
    },
    selectChanges (val) {
      console.log(val)
    },

    addRole() {
      this.dialogTitle = "新增同步设备";
      this.form = {
        ienterpriseName: '',
        sjEnterpriseId: '',
        deviceName: '',
        deviceType: '',
        deviceManufacturer: '',
        deviceAddr: '',
        videoUrl: '',
        remark: '',
      };
      this.roleDialog = true;
    },
    editRole(row) {
      console.log(row)
      this.dialogTitle = "编辑同步设备";
      this.form = {
        id: row.id,
        ienterpriseName: row.ienterpriseName,
        deviceId: row.deviceId,
        sjEnterpriseId: row.sjEnterpriseId,
        deviceName: row.deviceName,
        deviceType: row.deviceType,
        deviceManufacturer: row.deviceManufacturer,
        deviceAddr: row.deviceAddr,
        videoUrl: row.videoUrl,
        remark: row.remark,
      };
      this.roleDialog = true;
    },
    delRole(id) {
      this.qa.sjDeviceDoDeleteById({ id: id }).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.search();
      });
    },
    add() {
      this.$refs.cdForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle == "新增同步设备") {
            this.options1.forEach((item,index) => {
              if (item.ienterpriseName == this.form.ienterpriseName) {
                this.form.sjEnterpriseId = item.sjEnterpriseId;
              }
            })
            this.qa.sjDeviceDoInsert(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "新增同步设备成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.qa.sjDeviceDoUpdate(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "编辑同步设备成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    handleSizeChange(page) {
      this.pageInfo.pageSize = page;
      this.search();
    },
    handleCurrentChange(page) {
      this.pageInfo.pageNo = page;
      this.search();
    },
  },
};
</script>

<style scoped>
/* .role_index {
      width: 100%;
      padding: 20px 24px;
  } */
.role_content {
  background: #fff;
}
.role_content > h4 {
  padding: 24px 24px 10px;
  font-size: 16px;
  color: #1d1e1f;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid #efefef;
}
.role_button {
  padding: 10px 24px;
}
</style>